<template>
  <div id="tour-card" style="border-radius: 15px 100px 0 0; border: 1px solid cyan; padding: 1%">
    <b-row>
      <b-col md="4">
        <div
          class="
            h-100
            d-flex
            flex-wrap
            align-items-center
            justify-content-start
            card-global
          "
        >
          <div>
            <h3 class="text-center">
              Clinic daily income $ {{ CashStats.income }}
            </h3>
          </div>
        </div>
      </b-col>
      <b-col md="4">
        <div
          class="
            h-100
            d-flex
            flex-wrap
            align-items-center
            justify-content-start
            card-cash
          "
        >
          <div>
            <h3>Cash income $ {{ CashStats.current_balance }}</h3>
          </div>
        </div>
      </b-col>
      <b-col md="4">
        <div
          class="
            h-100
            d-flex
            flex-wrap
            align-items-center
            justify-content-start
            card-informative
          "
        >
          <div>
            <h3 class="text-custom">
              Credit card income $ {{ CashStats.current_descriptive }}
            </h3>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BCardTitle, BRow, BCol, BAvatar, BBadge } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BAvatar,
    BBadge,
  },
  props: {
    CashStats: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lanng="scss" scoped>
@media screen and (max-width: 430px) {
  .text-custom {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 375px) {
  .text-custom {
    font-size: 1.1rem;
  }
}
</style>
